<template>
   <!--  每个页面起始需要包一层div class为文件夹名称-->
   <div class="login">
      <!--    登录卡片-->
      <el-card class="box-card" style="text-align: center" shadow="hover">
         <!--      logo-->
         <p>
            <img :src="logoSrc" height="70" style="vertical-align: middle" />
            <span style="font-size: 30px; font-weight: bold; vertical-align: middle">鹏升中台项目</span>
         </p>
         <!--      form表单 -->
         <el-form :model="loginForm" :rules="rulesLoginForm" ref="loginForm">
            <el-form-item prop="username">
               <el-input type="text" ref='username' :size="$store.state.size" v-model="loginForm.username" prefix-icon="el-icon-user" autocomplete="off" @keyup.enter.native="next" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
               <el-input type="password" ref='pwd' :size="$store.state.size" v-model="loginForm.password" prefix-icon="el-icon-lock" autocomplete="off" placeholder="请输入密码" @keyup.enter.native="submitLogin('loginForm')"></el-input>
            </el-form-item>
            <el-form-item>
               <el-button :size="$store.state.size" style="width: 100%" type="primary" @click="submitLogin('loginForm')">登录
               </el-button>
            </el-form-item>
         </el-form>
      </el-card>
   </div>

</template>

<script>
import dayjs from 'dayjs'
export default {
   name: 'login',
   data() {
      let regUserName = new RegExp('^[\u4E00-\u9FA5A-Za-z0-9_]+$');
      let regUserNameLength = new RegExp('^.{0,20}$');
      let validateUserName = (rule, value, callback) => {
         value = value.replace(/\s+/g, '')
         if (value === '') {
            callback(new Error('请输入用户名称'));
         }
         if (!regUserName.test(value)) {
            callback(new Error('用户名只能输入中文、英文、数字和下划线'));
         }
         if (!regUserNameLength.test(value)) {
            callback(new Error('长度在 1 到 20 个字符'));
         }
         callback()
      };
      let regPassWord = new RegExp('^[A-Za-z0-9]+$');
      let regPassWordLength = new RegExp('^.{6,20}$');
      let validatePassWord = (rule, value, callback) => {
         value = value.replace(/\s+/g, '')
         if (value === '') {
            callback(new Error('请输入密码'));
         }
         // if (!regPassWord.test(value)) {
         //   callback(new Error('密码只能输入英文和数字'));
         // }
         if (!regPassWordLength.test(value)) {
            callback(new Error('长度在 6 到 20个字符'));
         }
         callback()
      };
      return {
         loading: false,
         logoSrc: require('@/assets/logo.png'),
         loginForm: {
            username: '',
            password: ''
         },
         rulesLoginForm: {
            username: [
               { validator: validateUserName, trigger: 'blur' }
            ],
            password: [
               { validator: validatePassWord, trigger: 'blur' }

            ]
         }
      }
   },
   methods: {
      next() {
         this.$refs.pwd.focus()
      },

      submitLogin(loginForm) {
         this.$refs[loginForm].validate((valid) => {
            if (valid) {
               const loading = this.$loading({
                  lock: true,
                  text: 'Loading',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
               });
               this.$server.login(this.loginForm).then((res) => {
                  this.loading = false
                  if (res.code === 200) {
                     sessionStorage.setItem('Authorization', res.data.token);
                     sessionStorage.setItem('permissionIds', JSON.stringify(res.data.permissionIds));
                     let userInfo = JSON.parse(res.data.userInfo)
                     userInfo.customer = res.data.customer
                     userInfo.roleName = res.data.roleName
                     sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
                     sessionStorage.setItem('sysParameterInfo', JSON.stringify(res.data.sysParameterInfo))
                     this.$message.success(res.msg);
                     let that = this;
                     setTimeout(() => {
                        that.$router.push('/console')
                        //this.$common.initParameterAssignment()
                     }, 500)
                     loading.close()
                  } else {
                     this.$message.warning(res.msg)
                     loading.close()
                  }
               }).catch(e => {
                  console.log(e)
                  loading.close()
               })
            }
         })
      }
   },
   mounted() {
      localStorage.clear()
      sessionStorage.clear()
      this.$nextTick(() => {
         this.$refs.username.focus()
      })
   }
}


</script>

<style scoped>
.login {
   padding-top: 10%;
   height: 100%;
   width: 100%;
   background-color: #2d3a4b;
   background-size: 100px 100%;
}

.box-card {
   margin: 0 auto;
   width: 480px;
}
</style>
